// new countUp('sixEight', 0, 680000, 0, 1, { separator: '' }).start()
// new countUp('oneEight', 0, 18000, 0, 1, { separator: '' }).start()
// new countUp('twoFour', 0, 24000, 0, 1, { separator: '' }).start()
// new countUp('threeEight', 0, 38000, 0, 1, { separator: '' }).start()
// new countUp('oneHundred', 1, 100, 0, 0.5, { separator: '' }).start()



// new countUp('sixEight1', 0, 680000, 0, 1, { separator: '' }).start()
// new countUp('oneEight1', 0, 18000, 0, 1, { separator: '' }).start()
// new countUp('twoFour1', 0, 24000, 0, 1, { separator: '' }).start()
// new countUp('threeEight1', 0, 38000, 0, 1, { separator: '' }).start()
// new countUp('oneHundred1', 1, 100, 0, 0.5, { separator: '' }).start()


$(function () {
    let i = 0;
    let came = true;
    let timer = null
    $('.scroll-box li').mouseenter(function () {
        console.log(11)
        came = false;
        i = $(this).index();
        $(`.scroll-box>li:nth-child(${i + 1})`).addClass('scrolly-selected').siblings().removeClass('scrolly-selected');
        $(`.scroll-right>div:nth-child(${i + 1})`).addClass('scrolly-selectedImg').siblings().removeClass('scrolly-selectedImg');
    });

    $('.scroll-box li').mouseenter(function () {
        came = false;
        clearTimeout(timer)
    }).mouseleave(function () {
        came = true;
        timer = setTimeout(function () {
            came = true;
        }, 3000);
    });

    function li_timer() {
        if (came) {
            i++;
            if (i == $('.scroll-box li').length) {
                $(`.scroll-box>li:nth-child(${i})`).addClass('scrolly-selected').siblings().removeClass('scrolly-selected');
                $(`.scroll-right>div:nth-child(${i})`).addClass('scrolly-selectedImg').siblings().removeClass('scrolly-selectedImg');
                i = 0;
            }
            $(`.scroll-box>li:nth-child(${i})`).addClass('scrolly-selected').siblings().removeClass('scrolly-selected');
            $(`.scroll-right>div:nth-child(${i})`).addClass('scrolly-selectedImg').siblings().removeClass('scrolly-selectedImg');
        }

    }
    setInterval(li_timer, 3000);// 每10秒切换

});

$(function () {
    let bgMap = [
        '../img/home/tab/aupuBg.png',
        '../img/home/tab/ltjBg.png',
        '../img/home/tab/zhiyangBg.png',
        '../img/home/tab/sanxiaBg.png',
        '../img/home/tab/wsdBg.png',
        '../img/home/tab/nsBg.png',
    ]
    let j = 0;
    let brand_came = true;
    let brandtimer = null
    $('.brand-header').mouseenter(function () {
        clearTimeout(brandtimer)
        brand_came = false;
    }).mouseleave(function () {
        brandtimer = setTimeout(function () {
            brand_came = true;
        }, 3000)
    });
    $('.brand-header li').mouseenter(function () {
        brand_came = false;
        j = $(this).index();
        $('.brand-scroll').css('background', `url(${bgMap[j]})`);
        $(`.brand-header>li:nth-child(${j + 1})`).addClass('brand-select').siblings().removeClass('brand-select');
        $(`.brand-bottom>div:nth-child(${j + 1})`).fadeIn({ duration: 500 }).siblings().hide();
    });

    function ti_timer() {
        if (brand_came) {
            j++;
            if (j == $('.brand-header li').length) {
                $('.brand-scroll').css('background', `url(${bgMap[j]})`);
                $(`.brand-header>li:nth-child(${j + 1})`).addClass('brand-select').siblings().removeClass('brand-select');
                $(`.brand-bottom>div:nth-child(${j + 1})`).fadeIn({ duration: 500 }).siblings().hide();
                j = 0;
            }
            $('.brand-scroll').css('background', `url(${bgMap[j]})`);
            $(`.brand-header>li:nth-child(${j + 1})`).addClass('brand-select').siblings().removeClass('brand-select');
            $(`.brand-bottom>div:nth-child(${j + 1})`).fadeIn({ duration: 500 }).siblings().hide();
        }
    }
    setInterval(ti_timer, 3000);
});

$(function () {
    let bgMap = [
        '../img/home/tab/aupuBg.png',
        '../img/home/tab/ltjBg.png',
        '../img/home/tab/zhiyangBg.png',
        '../img/home/tab/sanxiaBg.png',
        '../img/home/tab/wsdBg.png',
        '../img/home/tab/nsBg.png',
    ]
    let j = 0;
    $('.brand-header1 li').click(function () {
        j = $(this).index();
        console.log(j)
        $('.brand-scroll1').css('background', `url(${bgMap[j]})`)
        $(`.brand-header1>li:nth-child(${j + 1})`).addClass('brand-select1').siblings().removeClass('brand-select1');
        $(`.brand-bottom1>div:nth-child(${j + 1})`).fadeIn({ duration: 500 }).siblings().hide();
    })
})

$(function () {
    let apply_lock = false
    $('.apply-btn').click(function () {
        let connection = $('.mobile').val();
        if (!apply_lock && connection && connection.replace(/[0-9\-]/g, '').length == 0) {
            jQuery.support.cors = true
            let userPhoneReg = /^1[3-9]\d{9}$/;
            if (!userPhoneReg.test(connection)) {
                $.tooltip('请填写正确的联系方式');
                $('.mobile').focus();
            }
            apply_lock = true
            $.ajax({
                url: 'http://www.takecloud.cn/admin/index/addForm',
                data: {
                    connection: connection,
                    content: '',
                    username: '',
                    type: 1,
                },
                dataType: 'json',
                success: function (res) {
                    // alert('提交成功，近期会与您取得联系',2000,true);
                    if (res.code >= 0) {
                        $('.mobile').val('')
                        window.location.href = window.location.origin + '/' + 'form-done.html'
                    } else {
                        $.tooltip(res.msg);
                    }
                    apply_lock = false
                }
            })
        } else if (connection.replace(/[0-9\-]/g, '').length == 0) {
            alert('填写错误');
        } else {
            alert('填写错误');
        }
    })
})
